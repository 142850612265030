
.mozaique {
    overflow: hidden;
	text-align: center;
}

.thumb-block {
	.inline-block;
    position: relative;
	padding: 0;
    margin: @thumb-margin-top @thumb-margin-right @thumb-margin-bottom @thumb-margin-left;
	width: @thumb-width + (2 * @thumb-border-width);
    text-align: center;
	.thumb-inside {
		position: relative;
	}
    .thumb {
        a {
			border: @thumb-border;
			width: @thumb-width + (2 * @thumb-border-width);
			height: @thumb-height + (2 * @thumb-border-width);
            display: block;
			position: relative;
			overflow: hidden;
			zoom: 1;
			&:hover {
				border: @thumb-hover-border;
			}
        }
        img {
            width: 100%;
			&.hovered {
				box-sizing: content-box;
				position: absolute;
				width: 300%;
				height: 300%;
				top: 0;
				left: 0;
				margin-left: 0%;
				margin-top: 0%;
			}
        }
    }
    p {
        margin: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}