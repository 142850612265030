
@import "../../bootstrap/variables.less";
@body-bg:					#000;

@text-color:				#fff;
@link-color:				#fff;
@link-hover-color:			#fff;
@link-hover-decoration:		underline;

@font-family-base:			Arial,Helvetica,sans-serif;
@font-size-base:			14px;
@line-height-base:			1;

@font-size-h1:				24px;
@headings-small-color:		#fff;

@thumb-width:				310px;
@thumb-height:				233px;
@thumb-margin-top:			0;
@thumb-margin-right:		0;
@thumb-margin-bottom:		0;
@thumb-margin-left:			0;
@thumb-border-width:		1px;
@thumb-border:				@thumb-border-width solid #000;
@thumb-hover-border:		@thumb-border-width solid #000;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#fff;
@popup-text-color:			#000;
@popup-font-weight:			bold;

@social-links-margin:		5px 0;

@sites-list-font-size:				12px;
@sites-list-color:					#fff;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				20px;
@sites-list-title-weight:			bold;
@sites-list-title-decoration:		underline;

@parent-alert-padding:		10px;
@parent-alert-margin:		10px auto;
@parent-alert-border:		1px solid #fff;
@parent-alert-font-size:	11px;
@parent-alert-color:		#fff;
@parent-alert-bg:			transparent;

/** pager **/
@pager-padding: 			2px 5px;
@pager-text-decoration: 	none;
@pager-color: 				#000;
@pager-font-size: 			12px;
@pager-color-active: 		#fff;
@pager-font-weight-active: 	bold;
@pager-text-decoration-active: none;
@pager-font-size-mobile: 	12px;

@import "common/pagination.less";
@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/sites_list.less";
@import "common/parent_alert.less";
@import "common/modules.less";
@import "common/popup.less";
@import "common/mozaique.less";
body {
	line-height: 18px;
	font-size: 12px;
}
h1 {
	text-align: left;
	margin: 0 auto 15px;
	color: #fff;
	.pull-right {
		margin-right: 70px;
		.blue {
			color: #00D9FF;
		}
		font-size: 18px;
		text-transform: uppercase;
		margin-top: 10px;
		font-weight: bold;
	}
}
#language-switcher {
	float: right;
	margin: 0 10px;
}
.blue-stripe {
	padding: 4px 8px;
	color: #fff;
	background: #09B7F6;
	font-size: 12px;
	line-height: 18px;
}
.thumb-block {
	border-bottom: 1px solid #ccc;
	border-top: 1px solid #fff;
	padding: 4px 0;
	p {
		font-size: 12px;
		line-height: 16px;
		a {
			color: #000;
			text-decoration: underline;
		}
	}
	.thumb a {
		max-width: 100%;
	}
	.thumb-inside {
		margin: 0 1px;
	}
	.metadata {
		.duration {
			font-weight: bold;
			color: #000;
		}
		.quality {
			color: #ff0000;
		}
	}
}
.mozaique, .pagination {
	background: #ebebeb;
}
.pagination {
	margin: 0;
	padding: 5px;
	font-weight: bold;
	ul li a {
		text-decoration: none;
		padding: 2px 5px;
		border: 1px solid #ccc;
		display: inline-block;
		zoom: 1;
		color: black;
		&:hover, &.active {
			background: #00d9ff;
			color: #fff;
		}
		&.no-page {
			color: #000;

		}
	}
}
.footer-categories-links {
	font-size: 12px;
	list-style-type: none;
	li {
	display: inline-block;
		a {
			font-weight: bold;
			padding: 6px 10px;
			display: inline-block;
			zoom: 1;
			text-decoration: underline;
			color: #fff;
			}
	}
}
.sites-list, #sites-footer {
	margin: 20px;
}
@media screen and (max-width: 1024px) {
	h1 {
		.pull-right {
			float: none !important;
			display: block;
			margin: 10px;
		}
	}
	#sites-list ol li {
		text-align: center;
	}
	.pagination {
		white-space: normal;
	}
}